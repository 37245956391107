const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const days = [
  'Sun',
  'Mon',
  'Tue',
  'Wed',
  'Thu',
  'Fri',
  'Sat',
];

export function formatDate(dateStr, includeYear) {
  const d = new Date(dateStr);
  const day = d.getDay();
  const month = d.getMonth();
  const dt = d.getDate();
  let year = '';
  if (includeYear) {
    year = ` ${d.getFullYear()}`;
  }
  return `${days[day]}, ${dt} ${months[month]}${year}`;
}
